import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faArrowDownLong,
    faArrowRightFromBracket,
    faArrowUpLong,
    faArrowsRotate,
    faArrowsUpDown,
    faBox,
    faBoxes,
    faCalculator,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircleCheck,
    faCircleExclamation,
    faCircleInfo,
    faClockRotateLeft,
    faComment,
    faCopy,
    faDownload,
    faFile,
    faFiles,
    faFloppyDisk,
    faFolder,
    faFolders,
    faHome,
    faLeft,
    faLightbulbGear,
    faListCheck,
    faPencil,
    faPlus,
    faSearch,
    faTrash,
    faTriangleExclamation,
    faUser,
    faUsers,
    faVials,
    faXmark,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

export type IconProps = Omit<FontAwesomeIconProps, "icon">;
export type IconComponentType = (props: IconProps) => JSX.Element;

library.add(faArrowDownLong);
library.add(faArrowRightFromBracket);
library.add(faArrowUpLong);
library.add(faArrowsRotate);
library.add(faArrowsUpDown);
library.add(faBox);
library.add(faBoxes);
library.add(faCalculator);
library.add(faCheck);
library.add(faChevronDown);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faChevronUp);
library.add(faCircleCheck);
library.add(faCircleExclamation);
library.add(faCircleInfo);
library.add(faClockRotateLeft);
library.add(faComment);
library.add(faCopy);
library.add(faDownload);
library.add(faFile);
library.add(faFiles);
library.add(faFloppyDisk);
library.add(faFolder);
library.add(faFolders);
library.add(faHome);
library.add(faLeft);
library.add(faLightbulbGear);
library.add(faListCheck);
library.add(faPencil);
library.add(faPlus);
library.add(faSearch);
library.add(faTrash);
library.add(faTriangleExclamation);
library.add(faUser);
library.add(faUsers);
library.add(faVials);
library.add(faXmark);

export const AddIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "plus" }} />
);

export const BackIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "left" }} />
);

export const CheckIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "check" }} />
);

export const CloseIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "xmark" }} />
);

export const CollapseIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "chevron-up" }} />
);

export const ComponentsIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "vials" }} />
);

export const CopyIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "copy" }} />
);

export const DMAIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "calculator" }} />
);

export const DangerIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "exclamation-circle" }} />
);

export const DeleteIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "trash" }} />
);

export const DismissIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "xmark" }} />
);

export const DownloadIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "download" }} />
);

export const EditIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "pencil" }} />
);

export const ExpandIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "chevron-down" }} />
);

export const HistoryIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "clock-rotate-left" }} />
);

export const HomeIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "home" }} />
);

export const InfoIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "circle-info" }} />
);

export const IngredientIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "box" }} />
);

export const IngredientsIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "boxes" }} />
);

export const LogoutIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "arrow-right-from-bracket" }} />
);

export const NextIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "chevron-right" }} />
);

export const NoteIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "comment" }} />
);

export const NPDIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "lightbulb-gear" }} />
);

export const PreviousIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "chevron-left" }} />
);

export const ProjectIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "folder" }} />
);

export const ProjectsIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "folders" }} />
);

export const ProductIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "file" }} />
);

export const ProductsIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "files" }} />
);

export const ProductionStepsIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "list-check" }} />
);

export const RefreshIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "arrows-rotate" }} />
);

export const SaveIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "floppy-disk" }} />
);

export const SearchIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "search" }} />
);

export const SortAscendingIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "arrow-up-long" }} />
);

export const SortDescendingIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "arrow-down-long" }} />
);

export const SortUndefinedIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "arrows-up-down" }} />
);

export const SuccessIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "circle-check" }} />
);

export const UserIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "user" }} />
);

export const UsersIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "users" }} />
);

export const WarningIcon: IconComponentType = (props) => (
    <FontAwesomeIcon {...props} icon={{ prefix: "fal", iconName: "exclamation-triangle" }} />
);
